/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import { client, ErrorType } from './client'
export type GetPermitsParams = { cursor?: string };

export type GetNearbyCaseAddressesParams = { radius?: number; days?: number };

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export interface User {
  email?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  full_name?: string;
  id?: number;
}

export interface Token {
  access_token: string;
  token_type: string;
}

/**
 * An enumeration.
 */
export type SubscriptionTypeEnum = typeof SubscriptionTypeEnum[keyof typeof SubscriptionTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionTypeEnum = {
  malsnumer: 'malsnumer',
  heimilisfang: 'heimilisfang',
  gata: 'gata',
  kennitala: 'kennitala',
  radius: 'radius',
  leit: 'leit',
} as const;

export interface SubscriptionForm {
  active?: boolean;
  immediate?: boolean;
  radius?: number;
  council_types?: CouncilTypeEnum[];
}

export interface Subscription {
  active: boolean;
  immediate: boolean;
  search_query?: string;
  case?: Case;
  address?: Address;
  radius?: number;
  entity?: Entity;
  council_types?: CouncilTypeEnum[];
  id: number;
  type: SubscriptionTypeEnum;
}

export interface PlanPolygon {
  plan?: unknown;
  polygon?: unknown;
}

/**
 * An enumeration.
 */
export type PermitTypeEnum = typeof PermitTypeEnum[keyof typeof PermitTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermitTypeEnum = {
  nybygging: 'nybygging',
  vidbygging: 'vidbygging',
  breyting_inni: 'breyting_inni',
  breyting_uti: 'breyting_uti',
  nidurrif: 'nidurrif',
} as const;

/**
 * Can't use enums here because Pydantic does not support validating based on
enum name and not value. If we hack it to support that via a validate decorator
that in turn breaks other things (the -types endpoints)
 */
export interface PermitForm {
  units?: number;
  area_added?: number;
  area_subtracted?: number;
  building_type?: string;
  permit_type?: string;
}

export interface Municipality {
  id: number;
  name: string;
  councils?: BaseCouncil[];
}

export interface Msg {
  msg: string;
}

export interface Minute {
  id: number;
}

export interface Permit {
  units?: number;
  area_added?: number;
  area_subtracted?: number;
  building_type?: BuildingTypeEnum;
  permit_type?: PermitTypeEnum;
  minute?: Minute;
  created?: string;
}

export interface MapAddress {
  lat: number;
  lon: number;
  label: string;
  status?: CaseStatusEnum;
}

export interface MapEntityResponse {
  addresses: MapAddress[];
}

export interface MapCasesResponse {
  plan?: PlanPolygon;
  address: MapAddress;
  addresses: MapAddress[];
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface EnumListResponse {
  council_types: unknown[][];
  building_types: unknown[][];
  permit_types: unknown[][];
  subscription_types: unknown[][];
}

export interface Entity {
  kennitala: string;
  name: string;
}

/**
 * An enumeration.
 */
export type CouncilTypeEnum = typeof CouncilTypeEnum[keyof typeof CouncilTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CouncilTypeEnum = {
  byggingarfulltrui: 'byggingarfulltrui',
  skipulagsfulltrui: 'skipulagsfulltrui',
  skipulagsrad: 'skipulagsrad',
  borgarrad: 'borgarrad',
  borgarstjorn: 'borgarstjorn',
} as const;

/**
 * An enumeration.
 */
export type CaseStatusEnum = unknown;

export interface Case {
  id: number;
  serial: string;
  municipality?: Municipality;
}

/**
 * An enumeration.
 */
export type BuildingTypeEnum = typeof BuildingTypeEnum[keyof typeof BuildingTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BuildingTypeEnum = {
  einbylishus: 'einbylishus',
  fjolbylishus: 'fjolbylishus',
  gestahus: 'gestahus',
  geymsluskur: 'geymsluskur',
  hotel: 'hotel',
  idnadarhus: 'idnadarhus',
  parhus: 'parhus',
  radhus: 'radhus',
  sumarhus: 'sumarhus',
  verslun_skrifstofur: 'verslun_skrifstofur',
} as const;

export interface BodyResetPassword {
  token: string;
  new_password: string;
}

export interface BodyLoginAccessToken {
  grant_type?: string;
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
}

export interface BaseMunicipality {
  id: number;
  name: string;
}

export interface BaseCouncil {
  id: number;
  name: string;
  council_type: CouncilTypeEnum;
}

export interface ApiResponsePermit {
  units?: number;
  areaAdded?: number;
  areaSubtracted?: number;
  buildingType?: BuildingTypeEnum;
  permitType?: PermitTypeEnum;
  approved: string;
  url: string;
  address?: string;
  postcode?: string;
  municipality?: BaseMunicipality;
}

export interface Address {
  hnitnum: number;
  name: string;
  stadur_nf: string;
  municipality: Municipality;
}



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * @summary Get Municipalities
 */
export const getMunicipalities = (
    
 signal?: AbortSignal
) => {
      return client<Municipality[]>(
      {url: `/api/municipalities`, method: 'get', signal
    },
      );
    }
  

export const getGetMunicipalitiesQueryKey = () => [`/api/municipalities`];

    
export type GetMunicipalitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getMunicipalities>>>
export type GetMunicipalitiesQueryError = ErrorType<unknown>

export const useGetMunicipalities = <TData = Awaited<ReturnType<typeof getMunicipalities>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMunicipalities>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMunicipalitiesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMunicipalities>>> = ({ signal }) => getMunicipalities(signal);

  const query = useQuery<Awaited<ReturnType<typeof getMunicipalities>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Get Nearby Case Addresses
 */
export const getNearbyCaseAddresses = (
    hnitnum: number,
    params?: GetNearbyCaseAddressesParams,
 signal?: AbortSignal
) => {
      return client<MapCasesResponse>(
      {url: `/api/addresses/${hnitnum}/addresses`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetNearbyCaseAddressesQueryKey = (hnitnum: number,
    params?: GetNearbyCaseAddressesParams,) => [`/api/addresses/${hnitnum}/addresses`, ...(params ? [params]: [])];

    
export type GetNearbyCaseAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof getNearbyCaseAddresses>>>
export type GetNearbyCaseAddressesQueryError = ErrorType<HTTPValidationError>

export const useGetNearbyCaseAddresses = <TData = Awaited<ReturnType<typeof getNearbyCaseAddresses>>, TError = ErrorType<HTTPValidationError>>(
 hnitnum: number,
    params?: GetNearbyCaseAddressesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNearbyCaseAddresses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNearbyCaseAddressesQueryKey(hnitnum,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNearbyCaseAddresses>>> = ({ signal }) => getNearbyCaseAddresses(hnitnum,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNearbyCaseAddresses>>, TError, TData>(queryKey, queryFn, {enabled: !!(hnitnum), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Get Entity Addresses
 */
export const getEntityAddresses = (
    kennitala: string,
 signal?: AbortSignal
) => {
      return client<MapEntityResponse>(
      {url: `/api/entities/${kennitala}/addresses`, method: 'get', signal
    },
      );
    }
  

export const getGetEntityAddressesQueryKey = (kennitala: string,) => [`/api/entities/${kennitala}/addresses`];

    
export type GetEntityAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof getEntityAddresses>>>
export type GetEntityAddressesQueryError = ErrorType<HTTPValidationError>

export const useGetEntityAddresses = <TData = Awaited<ReturnType<typeof getEntityAddresses>>, TError = ErrorType<HTTPValidationError>>(
 kennitala: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEntityAddresses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEntityAddressesQueryKey(kennitala);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntityAddresses>>> = ({ signal }) => getEntityAddresses(kennitala, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEntityAddresses>>, TError, TData>(queryKey, queryFn, {enabled: !!(kennitala), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Follow Case
 */
export const followCase = (
    caseId: number,
 
) => {
      return client<unknown>(
      {url: `/api/follow/cases/${caseId}`, method: 'post'
    },
      );
    }
  


    export type FollowCaseMutationResult = NonNullable<Awaited<ReturnType<typeof followCase>>>
    
    export type FollowCaseMutationError = ErrorType<HTTPValidationError>

    export const useFollowCase = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followCase>>, TError,{caseId: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followCase>>, {caseId: number}> = (props) => {
          const {caseId} = props ?? {};

          return  followCase(caseId,)
        }

      return useMutation<Awaited<ReturnType<typeof followCase>>, TError, {caseId: number}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Unfollow Case
 */
export const unfollowCase = (
    caseId: number,
 
) => {
      return client<unknown>(
      {url: `/api/follow/cases/${caseId}`, method: 'delete'
    },
      );
    }
  


    export type UnfollowCaseMutationResult = NonNullable<Awaited<ReturnType<typeof unfollowCase>>>
    
    export type UnfollowCaseMutationError = ErrorType<HTTPValidationError>

    export const useUnfollowCase = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfollowCase>>, TError,{caseId: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unfollowCase>>, {caseId: number}> = (props) => {
          const {caseId} = props ?? {};

          return  unfollowCase(caseId,)
        }

      return useMutation<Awaited<ReturnType<typeof unfollowCase>>, TError, {caseId: number}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Follow Entity
 */
export const followEntity = (
    kennitala: string,
 
) => {
      return client<unknown>(
      {url: `/api/follow/entities/${kennitala}`, method: 'post'
    },
      );
    }
  


    export type FollowEntityMutationResult = NonNullable<Awaited<ReturnType<typeof followEntity>>>
    
    export type FollowEntityMutationError = ErrorType<HTTPValidationError>

    export const useFollowEntity = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followEntity>>, TError,{kennitala: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followEntity>>, {kennitala: string}> = (props) => {
          const {kennitala} = props ?? {};

          return  followEntity(kennitala,)
        }

      return useMutation<Awaited<ReturnType<typeof followEntity>>, TError, {kennitala: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Unfollow Entity
 */
export const unfollowEntity = (
    kennitala: string,
 
) => {
      return client<unknown>(
      {url: `/api/follow/entities/${kennitala}`, method: 'delete'
    },
      );
    }
  


    export type UnfollowEntityMutationResult = NonNullable<Awaited<ReturnType<typeof unfollowEntity>>>
    
    export type UnfollowEntityMutationError = ErrorType<HTTPValidationError>

    export const useUnfollowEntity = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfollowEntity>>, TError,{kennitala: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unfollowEntity>>, {kennitala: string}> = (props) => {
          const {kennitala} = props ?? {};

          return  unfollowEntity(kennitala,)
        }

      return useMutation<Awaited<ReturnType<typeof unfollowEntity>>, TError, {kennitala: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Follow Address
 */
export const followAddress = (
    hnitnum: number,
 
) => {
      return client<unknown>(
      {url: `/api/follow/addresses/${hnitnum}`, method: 'post'
    },
      );
    }
  


    export type FollowAddressMutationResult = NonNullable<Awaited<ReturnType<typeof followAddress>>>
    
    export type FollowAddressMutationError = ErrorType<HTTPValidationError>

    export const useFollowAddress = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followAddress>>, TError,{hnitnum: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followAddress>>, {hnitnum: number}> = (props) => {
          const {hnitnum} = props ?? {};

          return  followAddress(hnitnum,)
        }

      return useMutation<Awaited<ReturnType<typeof followAddress>>, TError, {hnitnum: number}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Unfollow Address
 */
export const unfollowAddress = (
    hnitnum: number,
 
) => {
      return client<unknown>(
      {url: `/api/follow/addresses/${hnitnum}`, method: 'delete'
    },
      );
    }
  


    export type UnfollowAddressMutationResult = NonNullable<Awaited<ReturnType<typeof unfollowAddress>>>
    
    export type UnfollowAddressMutationError = ErrorType<HTTPValidationError>

    export const useUnfollowAddress = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfollowAddress>>, TError,{hnitnum: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unfollowAddress>>, {hnitnum: number}> = (props) => {
          const {hnitnum} = props ?? {};

          return  unfollowAddress(hnitnum,)
        }

      return useMutation<Awaited<ReturnType<typeof unfollowAddress>>, TError, {hnitnum: number}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get Subscriptions
 */
export const getSubscriptions = (
    
 signal?: AbortSignal
) => {
      return client<Subscription[]>(
      {url: `/api/subscriptions`, method: 'get', signal
    },
      );
    }
  

export const getGetSubscriptionsQueryKey = () => [`/api/subscriptions`];

    
export type GetSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptions>>>
export type GetSubscriptionsQueryError = ErrorType<unknown>

export const useGetSubscriptions = <TData = Awaited<ReturnType<typeof getSubscriptions>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubscriptions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptions>>> = ({ signal }) => getSubscriptions(signal);

  const query = useQuery<Awaited<ReturnType<typeof getSubscriptions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Create Subscription
 */
export const createSubscription = (
    
 
) => {
      return client<Subscription>(
      {url: `/api/subscriptions`, method: 'post'
    },
      );
    }
  


    export type CreateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof createSubscription>>>
    
    export type CreateSubscriptionMutationError = ErrorType<unknown>

    export const useCreateSubscription = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubscription>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSubscription>>, TVariables> = () => {
          ;

          return  createSubscription()
        }

      return useMutation<Awaited<ReturnType<typeof createSubscription>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Update Subscription
 */
export const updateSubscription = (
    id: number,
    subscriptionForm: SubscriptionForm,
 
) => {
      return client<Subscription>(
      {url: `/api/subscriptions/${id}`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: subscriptionForm
    },
      );
    }
  


    export type UpdateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof updateSubscription>>>
    export type UpdateSubscriptionMutationBody = SubscriptionForm
    export type UpdateSubscriptionMutationError = ErrorType<HTTPValidationError>

    export const useUpdateSubscription = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSubscription>>, TError,{id: number;data: SubscriptionForm}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSubscription>>, {id: number;data: SubscriptionForm}> = (props) => {
          const {id,data} = props ?? {};

          return  updateSubscription(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSubscription>>, TError, {id: number;data: SubscriptionForm}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Delete Subscription
 */
export const deleteSubscription = (
    id: number,
 
) => {
      return client<unknown>(
      {url: `/api/subscriptions/${id}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSubscription>>>
    
    export type DeleteSubscriptionMutationError = ErrorType<HTTPValidationError>

    export const useDeleteSubscription = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubscription>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSubscription>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteSubscription(id,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSubscription>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get Permits
 */
export const getPermits = (
    params?: GetPermitsParams,
 signal?: AbortSignal
) => {
      return client<ApiResponsePermit[]>(
      {url: `/api/permits`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPermitsQueryKey = (params?: GetPermitsParams,) => [`/api/permits`, ...(params ? [params]: [])];

    
export type GetPermitsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermits>>>
export type GetPermitsQueryError = ErrorType<HTTPValidationError>

export const useGetPermits = <TData = Awaited<ReturnType<typeof getPermits>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetPermitsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermits>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPermitsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermits>>> = ({ signal }) => getPermits(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPermits>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Get Permit
 */
export const getPermit = (
    minuteId: number,
 signal?: AbortSignal
) => {
      return client<Permit>(
      {url: `/api/minutes/${minuteId}/permit`, method: 'get', signal
    },
      );
    }
  

export const getGetPermitQueryKey = (minuteId: number,) => [`/api/minutes/${minuteId}/permit`];

    
export type GetPermitQueryResult = NonNullable<Awaited<ReturnType<typeof getPermit>>>
export type GetPermitQueryError = ErrorType<HTTPValidationError>

export const useGetPermit = <TData = Awaited<ReturnType<typeof getPermit>>, TError = ErrorType<HTTPValidationError>>(
 minuteId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermit>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPermitQueryKey(minuteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermit>>> = ({ signal }) => getPermit(minuteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPermit>>, TError, TData>(queryKey, queryFn, {enabled: !!(minuteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Update Permit
 */
export const updatePermit = (
    minuteId: number,
    permitForm: PermitForm,
 
) => {
      return client<Permit>(
      {url: `/api/minutes/${minuteId}/permit`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: permitForm
    },
      );
    }
  


    export type UpdatePermitMutationResult = NonNullable<Awaited<ReturnType<typeof updatePermit>>>
    export type UpdatePermitMutationBody = PermitForm
    export type UpdatePermitMutationError = ErrorType<HTTPValidationError>

    export const useUpdatePermit = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePermit>>, TError,{minuteId: number;data: PermitForm}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePermit>>, {minuteId: number;data: PermitForm}> = (props) => {
          const {minuteId,data} = props ?? {};

          return  updatePermit(minuteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePermit>>, TError, {minuteId: number;data: PermitForm}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Get Enums
 */
export const getEnums = (
    
 signal?: AbortSignal
) => {
      return client<EnumListResponse>(
      {url: `/api/_enums`, method: 'get', signal
    },
      );
    }
  

export const getGetEnumsQueryKey = () => [`/api/_enums`];

    
export type GetEnumsQueryResult = NonNullable<Awaited<ReturnType<typeof getEnums>>>
export type GetEnumsQueryError = ErrorType<unknown>

export const useGetEnums = <TData = Awaited<ReturnType<typeof getEnums>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEnums>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEnumsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnums>>> = ({ signal }) => getEnums(signal);

  const query = useQuery<Awaited<ReturnType<typeof getEnums>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Read User Me
 */
export const readUserMe = (
    
 signal?: AbortSignal
) => {
      return client<User>(
      {url: `/api/me`, method: 'get', signal
    },
      );
    }
  

export const getReadUserMeQueryKey = () => [`/api/me`];

    
export type ReadUserMeQueryResult = NonNullable<Awaited<ReturnType<typeof readUserMe>>>
export type ReadUserMeQueryError = ErrorType<unknown>

export const useReadUserMe = <TData = Awaited<ReturnType<typeof readUserMe>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readUserMe>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getReadUserMeQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readUserMe>>> = ({ signal }) => readUserMe(signal);

  const query = useQuery<Awaited<ReturnType<typeof readUserMe>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * @summary Login Access Token
 */
export const loginAccessToken = (
    bodyLoginAccessToken: BodyLoginAccessToken,
 
) => {const formUrlEncoded = new URLSearchParams();
if(bodyLoginAccessToken.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyLoginAccessToken.grant_type)
 }
formUrlEncoded.append('username', bodyLoginAccessToken.username)
formUrlEncoded.append('password', bodyLoginAccessToken.password)
if(bodyLoginAccessToken.scope !== undefined) {
 formUrlEncoded.append('scope', bodyLoginAccessToken.scope)
 }
if(bodyLoginAccessToken.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyLoginAccessToken.client_id)
 }
if(bodyLoginAccessToken.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyLoginAccessToken.client_secret)
 }

      return client<Token>(
      {url: `/api/login/access-token`, method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
       data: formUrlEncoded
    },
      );
    }
  


    export type LoginAccessTokenMutationResult = NonNullable<Awaited<ReturnType<typeof loginAccessToken>>>
    export type LoginAccessTokenMutationBody = BodyLoginAccessToken
    export type LoginAccessTokenMutationError = ErrorType<HTTPValidationError>

    export const useLoginAccessToken = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginAccessToken>>, TError,{data: BodyLoginAccessToken}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginAccessToken>>, {data: BodyLoginAccessToken}> = (props) => {
          const {data} = props ?? {};

          return  loginAccessToken(data,)
        }

      return useMutation<Awaited<ReturnType<typeof loginAccessToken>>, TError, {data: BodyLoginAccessToken}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Recover Password
 */
export const recoverPassword = (
    email: string,
 
) => {
      return client<Msg>(
      {url: `/api/password-recovery/${email}`, method: 'post'
    },
      );
    }
  


    export type RecoverPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof recoverPassword>>>
    
    export type RecoverPasswordMutationError = ErrorType<HTTPValidationError>

    export const useRecoverPassword = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recoverPassword>>, TError,{email: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recoverPassword>>, {email: string}> = (props) => {
          const {email} = props ?? {};

          return  recoverPassword(email,)
        }

      return useMutation<Awaited<ReturnType<typeof recoverPassword>>, TError, {email: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * @summary Reset Password
 */
export const resetPassword = (
    bodyResetPassword: BodyResetPassword,
 
) => {
      return client<Token>(
      {url: `/api/reset-password`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: bodyResetPassword
    },
      );
    }
  


    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    export type ResetPasswordMutationBody = BodyResetPassword
    export type ResetPasswordMutationError = ErrorType<HTTPValidationError>

    export const useResetPassword = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: BodyResetPassword}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {data: BodyResetPassword}> = (props) => {
          const {data} = props ?? {};

          return  resetPassword(data,)
        }

      return useMutation<Awaited<ReturnType<typeof resetPassword>>, TError, {data: BodyResetPassword}, TContext>(mutationFn, mutationOptions)
    }
    
