export const MagnifyingGlass = (props) => {
  return (
    <svg
      className="inline h-5 w-5"
      width="112px"
      height="112px"
      viewBox="0 0 112 112"
    >
      <g transform="translate(-622.000000, -48.000000)" fillRule="nonzero">
        <path d="M726.662109,159.421875 C730.470703,159.421875 733.166016,156.492188 733.166016,152.742188 C733.166016,150.984375 732.580078,149.34375 731.291016,148.054688 L703.693359,120.398438 C709.494141,112.78125 712.951172,103.347656 712.951172,93.09375 C712.951172,68.3085938 692.677734,48.0351562 667.892578,48.0351562 C643.107422,48.0351562 622.833984,68.3085938 622.833984,93.09375 C622.833984,117.878906 643.107422,138.152344 667.892578,138.152344 C677.677734,138.152344 686.701172,134.988281 694.083984,129.714844 L721.857422,157.488281 C723.146484,158.777344 724.845703,159.421875 726.662109,159.421875 Z M667.892578,128.425781 C648.556641,128.425781 632.560547,112.429688 632.560547,93.09375 C632.560547,73.7578125 648.556641,57.7617188 667.892578,57.7617188 C687.228516,57.7617188 703.224609,73.7578125 703.224609,93.09375 C703.224609,112.429688 687.228516,128.425781 667.892578,128.425781 Z"></path>
      </g>
    </svg>
  );
};

export const PersonFill = (props) => {
  return (
    <svg
      width="112px"
      height="112px"
      className="inline h-5 w-5"
      viewBox="0 0 112 112"
    >
      <g transform="translate(-628.000000, -53.000000)" fillRule="nonzero">
        <path d="M677.529297,104.109375 C690.068359,104.109375 700.966797,92.859375 700.966797,78.2109375 C700.966797,63.7382812 690.068359,53.015625 677.529297,53.015625 C664.990234,53.015625 654.091797,63.9726562 654.091797,78.328125 C654.091797,92.859375 664.931641,104.109375 677.529297,104.109375 Z M714.267578,158.367188 C723.525391,158.367188 726.806641,155.730469 726.806641,150.574219 C726.806641,135.457031 707.880859,114.597656 677.470703,114.597656 C647.119141,114.597656 628.193359,135.457031 628.193359,150.574219 C628.193359,155.730469 631.474609,158.367188 640.673828,158.367188 L714.267578,158.367188 Z"></path>
      </g>
    </svg>
  );
};

export const TrashFill = (props) => {
  return (
    <svg
      width="100px"
      height="113px"
      className="inline h-5 w-5"
      viewBox="0 0 100 113"
    >
      <g fillRule="nonzero">
        <path d="M25.4649 113H74.5868C80.9917 113 84.8657 109.441 85.124 103.046L88.4298 27.6956H96.1777C98.2955 27.6956 100 25.8905 100 23.7759C100 21.6613 98.2955 19.9078 96.1777 19.9078H72.624V12.1716C72.624 4.74487 67.6136 0 59.6591 0H40.3409C32.3864 0 27.4277 4.74487 27.4277 12.1716V19.9078H3.82231C1.7562 19.9078 0 21.7129 0 23.7759C0 25.8905 1.7562 27.6956 3.82231 27.6956H11.6736L14.9793 103.046C15.2376 109.441 19.0599 113 25.4649 113ZM35.6921 19.9078V12.6874C35.6921 9.7476 37.8099 7.68462 40.9607 7.68462H59.0909C62.2417 7.68462 64.4112 9.7476 64.4112 12.6874V19.9078H35.6921ZM28.4607 95.0005L26.1364 38.7841C26.0331 36.618 27.5826 35.0192 29.5455 35.0192C31.3533 35.0192 32.9029 36.7211 33.0062 38.7841L35.124 95.0005C35.1756 97.115 33.7293 98.817 31.7149 98.817C29.8554 98.817 28.5124 97.2697 28.4607 95.0005ZM46.5393 95.0005L46.4876 38.7841C46.4876 36.7211 48.1405 35.0192 50 35.0192C51.9112 35.0192 53.564 36.7211 53.564 38.7841V95.0005C53.564 97.115 51.8595 98.817 50 98.817C48.1405 98.817 46.5393 97.115 46.5393 95.0005ZM64.9793 95.0005L67.0971 38.7841C67.1488 36.7211 68.6983 35.0192 70.5578 35.0192C72.469 35.0192 74.0186 36.618 73.9669 38.7841L71.6426 95.0005C71.5909 97.2697 70.1963 98.817 68.2851 98.817C66.3223 98.817 64.9277 97.115 64.9793 95.0005Z" />
      </g>
    </svg>
  );
};

export const BadgePlusRadiowavesRight = (props) => {
  return (
    <svg
      width="105"
      height="84"
      viewBox="0 0 105 84"
      className="inline h-5 w-5"
    >
      <path d="M87.9364 83.4273C89.457 84.4816 91.5688 84.0598 92.7936 82.3309C100.523 71.2822 105 57.0707 105 42.0158C105 26.9609 100.481 12.7072 92.7936 1.65849C91.5688 -0.0705046 89.4992 -0.492211 87.9364 0.604225C86.3315 1.70066 86.078 3.80919 87.3029 5.58036C94.0607 15.5748 98.2844 28.3947 98.2844 42.0158C98.2844 55.6369 94.2297 68.6254 87.2607 78.4512C86.0358 80.1802 86.2892 82.2887 87.9364 83.4273ZM70.8729 71.9147C72.5201 73.0112 74.5475 72.5894 75.7301 70.9026C81.3476 63.1432 84.5575 52.8114 84.5575 42.0158C84.5575 31.1779 81.3898 20.8461 75.7301 13.0867C74.5052 11.4421 72.5201 11.0204 70.8729 12.1168C69.3101 13.2132 69.0567 15.2796 70.3238 17.0929C74.8854 23.8402 77.7574 32.6961 77.7574 42.0158C77.7574 51.3355 75.0121 60.2756 70.2816 66.9386C69.0567 68.7519 69.3101 70.8183 70.8729 71.9147ZM21.9208 63.8601C33.7892 63.8601 43.7993 53.95 43.7993 42.0158C43.7993 30.0393 33.9159 20.1292 21.9208 20.1292C9.92558 20.1292 0 30.0393 0 42.0158C0 54.0344 9.92558 63.8601 21.9208 63.8601ZM54.0205 60.5287C55.4988 61.5407 57.5261 61.2034 58.751 59.5587C62.0877 55.1308 64.0306 48.6365 64.0306 42.0158C64.0306 35.395 62.0455 28.9429 58.751 24.4306C57.5261 22.786 55.4988 22.4486 54.0205 23.4607C52.2043 24.6836 51.9509 26.8343 53.3447 28.6898C55.7522 31.9792 57.2305 36.9975 57.2305 42.0158C57.2305 47.0341 55.71 52.0524 53.3025 55.3838C51.9932 57.1972 52.2466 59.2635 54.0205 60.5287ZM21.9208 56.2273C20.4002 56.2273 19.1331 55.173 19.1331 53.5705V44.5882H10.8126C9.37651 44.5882 8.19389 43.4074 8.19389 41.9736C8.19389 40.582 9.37651 39.359 10.8126 39.359H19.1331V30.4188C19.1331 28.8164 20.4002 27.8043 21.9208 27.8043C23.399 27.8043 24.7084 28.8164 24.7084 30.4188V39.359H32.9867C34.465 39.359 35.6054 40.582 35.6054 41.9736C35.6054 43.4074 34.465 44.5882 32.9867 44.5882H24.7084V53.5705C24.7084 55.173 23.399 56.2273 21.9208 56.2273Z" />
    </svg>
  );
};

export const Ellipsis = (props) => {
  return (
    <svg
      width="112"
      height="24"
      viewBox="0 0 112 24"
      className="inline h-5 w-5"
    >
      <path
        d="M23.9321 12C23.9321 5.34 18.5875 0 11.877 0C5.34464 0 0 5.34 0 12C0 18.66 5.34464 24 11.877 24C18.5875 24 23.9321 18.66 23.9321 12ZM67.877 12C67.877 5.34 62.5917 0 56 0C49.4677 0 44.1824 5.34 44.1824 12C44.1824 18.66 49.4677 24 56 24C62.5917 24 67.877 18.66 67.877 12ZM112 12C112 5.34 106.715 0 100.123 0C93.4125 0 88.1273 5.34 88.1273 12C88.1273 18.66 93.4125 24 100.123 24C106.715 24 112 18.66 112 12Z"
        fill="black"
      />
    </svg>
  );
};
